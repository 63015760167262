import {FC} from 'react';
import {Heading} from '~/modules/Common/components/Heading';

interface AppearanceHeadlineProps {
  title: string;
}

export const AppearanceHeadline: FC<AppearanceHeadlineProps> = ({title}) => (
  <Heading
    as="h3"
    size="copyBold"
    className="pb-2 px-2 md:px-0 !font-internal"
    internal
  >
    {title}
  </Heading>
);
