import {Button} from '~/modules/Common/components/Button';
import {FC} from 'react';
import {Heading} from '~/modules/Common/components/Heading';
import {Modal} from 'flowbite-react';
import {Text} from '~/modules/Common/components/Text';

interface DeleteImageModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteImageModal: FC<DeleteImageModalProps> = ({
  onConfirm,
  onClose,
}) => (
  <Modal dismissible show className="h-screen" onClose={onClose} size="md">
    <Modal.Header>
      <Heading as="span" size="headlineSm">
        Delete Image
      </Heading>
    </Modal.Header>
    <Modal.Body className="py-2">
      <Text size="copy" as="p" className="block py-2">
        Are you sure you want to delete your image?
      </Text>
    </Modal.Body>
    <Modal.Footer className="flex flex-row gap-4 justify-end flex-auto">
      <Button color="light" type="button" onClick={onClose}>
        Cancel
      </Button>
      <Button type="button" onClick={onConfirm}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);
