import {FC, useEffect, useMemo, useRef, useState} from 'react';

import {AppearanceHeadline} from './AppearanceHeadline';
import {ButtonWithImage} from './ButtonWithImage';
import ColorThief from 'colorthief';
import {DeleteImageModal} from './DeleteImageModal';
import {ImageForm} from '~/modules/Expert/components/ImageForm';
import {Text} from '~/modules/Common/components/Text';

interface BackgroundImageSelectorProps {
  backgroundImage: string;
  userBackgroundImages: string[];
  userBackgroundMedia: string[];
  onBackgroundImageChange: (image: string) => void;
  onBackgroundImagesChange: (images: string[], image: string) => void;
  onColorThief: (color: [string, string, string][]) => void;
}

export const baseBackgroundImages = [
  {
    src: 'https://res.cloudinary.com/dogz7twzq/image/upload/v1693592087/base5_dinebu.jpg',
  },
  {
    src: 'https://res.cloudinary.com/dogz7twzq/image/upload/v1693592087/base1_apiih0.jpg',
  },
  {
    src: 'https://res.cloudinary.com/dogz7twzq/image/upload/v1693592087/base2_emtli5.jpg',
  },
  {
    src: 'https://res.cloudinary.com/dogz7twzq/image/upload/v1693592087/base4_btftab.jpg',
  },
  {
    src: 'https://res.cloudinary.com/dogz7twzq/image/upload/v1693592087/base3_mugemr.jpg',
  },
];

export const BackgroundImageSelector: FC<BackgroundImageSelectorProps> = ({
  backgroundImage,
  userBackgroundImages,
  userBackgroundMedia,
  onBackgroundImageChange,
  onBackgroundImagesChange,
  onColorThief,
}) => {
  const backgroundImageRef = useRef(null);
  const isColorThiefEnabled = useRef(false);
  const [isDeleteImageModalVisible, setIsDeleteImageModalVisible] =
    useState(false);

  const onRemoveImageClick = () => {
    setIsDeleteImageModalVisible(true);
  };

  const removeImage = () => {
    onBackgroundImageChange('');
    setIsDeleteImageModalVisible(false);
  };

  const isBaseImage = useMemo(
    () => baseBackgroundImages.some((image) => image.src === backgroundImage),
    [backgroundImage],
  );

  const allUserMedia = useMemo(
    () =>
      (Array.isArray(userBackgroundImages) ? userBackgroundImages : [])
        .concat(userBackgroundMedia)
        .filter(Boolean),
    [userBackgroundImages, userBackgroundMedia],
  );

  const Imgs = allUserMedia.map((image, index) => (
    <ButtonWithImage
      key={image}
      className="!p-1 block snap-start"
      image={
        <ImageContainer image={image}>
          <img
            alt=""
            src={image}
            crossOrigin="anonymous"
            style={{opacity: 0.001}}
            {...(backgroundImage === image
              ? {
                  id: 'uploaded_image',
                  ref: backgroundImageRef,
                }
              : {
                  id: `userimage${index}`,
                })}
          />
        </ImageContainer>
      }
      title=""
      onClick={() => {
        onBackgroundImageChange(image);
        stealColor(document.querySelector(`#userimage${index}`));
      }}
      isSelected={backgroundImage === image}
    />
  ));

  const stealColor = (selector) => {
    const colorThief = new ColorThief();
    onColorThief(colorThief.getPalette(selector, 3));
  };

  useEffect(() => {
    if (backgroundImageRef.current?.complete && isColorThiefEnabled.current) {
      stealColor(document.querySelector('#uploaded_image'));
      isColorThiefEnabled.current = false;
    }
  }, [
    isColorThiefEnabled.current,
    onColorThief,
    backgroundImage,
    backgroundImageRef.current,
    backgroundImageRef.current?.complete,
  ]);

  return (
    <div>
      <AppearanceHeadline title="Background Image" />
      <div className="center-rowgap-2 md:gap-4 swimlane hiddenScroll md:overflow-x-auto !pl-0 py-2 m-0">
        {Imgs}
        {baseBackgroundImages.map((image, index) => (
          <ButtonWithImage
            className="!p-1 block snap-start"
            key={image.src}
            image={
              <ImageContainer image={image.src}>
                <img
                  src={image.src}
                  alt=""
                  id={`bgimage${index}`}
                  crossOrigin="anonymous"
                  className="fadeIn"
                />
              </ImageContainer>
            }
            title=""
            onClick={() => {
              onBackgroundImageChange(image.src);
              stealColor(document.querySelector(`#bgimage${index}`));
            }}
            isSelected={image.src === backgroundImage}
          />
        ))}
      </div>
      <div className="my-2">
        <Text size="copy" as="p" className="mb-2 px-2 md:px-0" internal>
          Upload your own:
        </Text>
        <ImageForm
          className="inline-flex"
          uploadedFileUrl={isBaseImage ? backgroundImage : ''}
          onChange={(src) => {
            isColorThiefEnabled.current = true;
            const nextBgImages = [
              src,
              ...(Array.isArray(userBackgroundImages)
                ? userBackgroundImages
                : []),
            ].slice(0, 5); // dont want infinite storage
            onBackgroundImagesChange(nextBgImages, src);
          }}
          name="profile"
          width={630}
          height={504}
          onRemoveImageClick={onRemoveImageClick}
        />
        {isDeleteImageModalVisible && (
          <DeleteImageModal
            onClose={() => setIsDeleteImageModalVisible(false)}
            onConfirm={removeImage}
          />
        )}
      </div>
    </div>
  );
};

const ImageContainer = ({children, image}) => {
  return (
    <div
      className="h-[72px] w-[72px] aspect-square"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {children}
    </div>
  );
};
