import {FC, ReactNode} from 'react';

import {Text} from '~/modules/Common/components/Text';

type ButtonProps = JSX.IntrinsicElements['button'];

interface ButtonWithImageProps extends ButtonProps {
  title: string;
  image: ReactNode;
  isSelected: boolean;
  className?: string;
}

export const ButtonWithImage: FC<ButtonWithImageProps> = ({
  title,
  image,
  isSelected,
  className = '',
  ...rest
}) => (
  <button
    {...rest}
    className={`p-2 border-2 border-solid rounded-md ${
      isSelected ? 'border-codGray bg-codGray100' : 'border-codGray400'
    } flex flex-col items-center gap-2 ${className}`}
  >
    {image}
    {title && (
      <Text as="span" size="fine" internal>
        {title}
      </Text>
    )}
  </button>
);
